<template>
  <div>
    <div class="checkout-switcher" v-if="enableB2BSwitcher && isLoaded">
      <a href="javascript:void(0);" @click.prevent="setCustomerType(2)" v-if="parseInt(customerType) === 1">{{ $t('Business Customer?') }}</a>
      <a href="javascript:void(0);" @click.prevent="setCustomerType(1)" v-if="parseInt(customerType) === 2">{{ $t('Private Customer?') }}</a>
    </div>

    <div :id="containerId" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import config from 'config'

export default {
  data: () => ({
    isLoaded: false,
    customerType: 1,
    id: 'collector-payment',
    containerId: 'collector-payment-iframe'
  }),
  computed: {
    ...mapGetters('collector', ['getIframeInfo']),
    enableB2BSwitcher () {
      return config.checkout.enableB2BSwitcher
    }
  },
  beforeMount () {
    this.isLoaded = document.getElementById(this.id)
    if (this.isLoaded) {
      return
    }
    this.$nextTick(async () => {
      this.bootstrap()
    })
  },
  watch: {
    getIframeInfo () {
      this.bootstrap()
    }
  },
  beforeDestroy () {
    this.$nextTick(() => {
      this.clear()
    })
  },
  methods: {
    async setCustomerType (value) {
      if (!this.isLoaded) {
        return;
      }

      this.isLoaded = false

      await this.$store.dispatch('collector/setCustomerType', value, { root: true })

      this.isLoaded = true
    },
    bootstrap () {
      if (!this.getIframeInfo) return
      const { src, token, lang, version, customer_type } = this.getIframeInfo
      const isnUnready = !(src && token && lang && this.id)
      if (isnUnready) return

      this.clear();

      const script = document.createElement('script')
      script.setAttribute('id', this.id)
      script.setAttribute('data-container-id', this.containerId)
      script.setAttribute('src', src)
      script.setAttribute('data-token', token)
      script.setAttribute('data-lang', lang)
      script.setAttribute('data-version', version)
      document.body.appendChild(script)

      this.customerType = customer_type
      this.isLoaded = true
    },
    clear () {
      const script = document.getElementById(this.id)
      if (script) {
        script.remove()

        const iframeContainer = document.getElementById(this.containerId)

        if (iframeContainer) {
          iframeContainer.innerHTML = '';
        }
      }
    }
  }
};
</script>
<style lang="scss">
.checkout-switcher {
  display: block;
  text-align: right;

  a {
    font-size: var(--font-size--sm);
  }
}
</style>
