<template>
  <div id="checkout" :class="`checkout_parent subscription-${1} first-step`">
    <div class="checkout">
      <div class="checkout__main">
        <SfSteps :active="currentStepIndex" :steps="steps.map(step => step.name)" @change="changeStep">
          <SfStep :name="steps[0].name">
            <OPersonalDetails :is-active="true" :show-estimated-delivery="true" :initial-load="initialCartLoad" />
          </SfStep>
          <SfStep :name="steps[1].name">
            <OPersonalDetails :is-active="false" v-show="!initialCartLoad" />
            <OShipping :is-active="true" v-show="!initialCartLoad" />
          </SfStep>
          <SfStep :name="steps[2].name">
            <OPayment :is-active="true" v-show="!initialCartLoad" />
          </SfStep>
        </SfSteps>
      </div>
      <div class="checkout__aside">
        <h3 @click="openAccordion(1)" class="flex">
          <svg
            class="cart-icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            width="20"
            height="20"
          >
            <g fill="none" fill-rule="evenodd">
              <path stroke="currentColor" stroke-width="2" d="M3.5 0v13.65h10.182L17.5 4.095h-14" />
              <ellipse
                fill="currentColor"
                fill-rule="nonzero"
                cx="4"
                cy="17.9"
                rx="1.5"
                ry="1.575"
              />
              <ellipse
                fill="currentColor"
                fill-rule="nonzero"
                cx="12"
                cy="17.9"
                rx="1.5"
                ry="1.575"
              />
            </g>
          </svg>
          <SfProperty
            :name="(activeAccordion) ? $t('Hide Details') : $t('More Details')"
            :value="prices.grand_total | price"
            class="sf-property--full-width sf-property--large property"
            style="--property-name-font-weight: var(--font-weight--semibold);"
          />
        </h3>
        <transition name="fade">
          <OOrderSummary class="checkout__aside-order" :step="currentStepIndex + 1" :initial-load="initialCartLoad" />
        </transition>
        <APromoCode
          class="m-promo-code"
          v-if="(currentStepIndex + 1) < 3"
          :should-invalidate-shipping-methods="(currentStepIndex + 1) > 1"
          :is-initial-load="initialCartLoad"
        />
      </div>
      <div class="checkout__aside_bottom">
        <lazy-hydrate when-visible>
          <OCheckoutFaqs v-if="checkoutFaqsBlock !== ''" class="checkout__faq-content-wrapper" :content="checkoutFaqsBlock" />
        </lazy-hydrate>
        <lazy-hydrate when-visible>
          <OCheckoutFaqs v-if="showCheckoutFaqsStepBlock(currentStepIndex) !== ''" class="checkout__faq-content-wrapper" :content="showCheckoutFaqsStepBlock(currentStepIndex)" />
        </lazy-hydrate>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import Checkout from '@vue-storefront/core/pages/Checkout';
import SfSteps from '@storefront-ui/vue/src/components/molecules/SfSteps/SfSteps.vue';
import SfStep from '@storefront-ui/vue/src/components/molecules/SfSteps/_internal/SfStep.vue';
import OPayment from '~/theme/components/organisms/o-payment';
import OShipping from '~/theme/components/organisms/o-shipping';
import OOrderSummary from '~/theme/components/organisms/o-order-summary';
import OCheckoutFaqs from '~/theme/components/organisms/o-checkout-faqs';
import OPersonalDetails from '~/theme/components/organisms/o-personal-details';
import APromoCode from '~/theme/components/atoms/a-promo-code';
import SfProperty from '@storefront-ui/vue/src/components/atoms/SfProperty/SfProperty.vue';
import LazyHydrate from 'vue-lazy-hydration';
import config from 'config';
import { isServer } from '@vue-storefront/core/helpers';

export default {
  name: 'Checkout',
  components: {
    SfSteps,
    SfStep,
    OPersonalDetails,
    OShipping,
    OPayment,
    OOrderSummary,
    SfProperty,
    APromoCode,
    OCheckoutFaqs,
    LazyHydrate
  },
  mixins: [Checkout],
  mounted () {
    if (window.location.hash) {
      let sectionToActivate = window.location.hash.replace('#', '')
      if (sectionToActivate === this.steps[2].key) {
        sectionToActivate = this.steps[0].key
      }
      this.activateSection(sectionToActivate)
    }
    this.$bus.$on('prepare-order', this.onPrepareOrder)
    this.checkIsMobileView()
    window.addEventListener('resize', this.checkIsMobileView)
    this.hideTrustBadge()
    if (isServer) return;
    setTimeout(() => this.openAccordionNew(), 2000);
  },
  beforeDestroy () {
    this.$bus.$off('prepare-order', this.onPrepareOrder)
  },
  data () {
    return {
      activeAccordion: 0,
      isMobileProductDetails: true,
      isMobile: false,
      steps: [
        {
          key: 'personalDetails',
          name: this.$t('Products'),
          component: OPersonalDetails
        },
        {
          key: 'shipping',
          name: this.$t('Shipping'),
          component: OShipping
        },
        {
          key: 'payment',
          name: this.$t('Payment'),
          component: OPayment
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      segmentTotals: 'cart/getTotals',
      cmsBlocks: 'cmsBlock/getCmsBlocks'
    }),
    currentStepIndex () {
      return this.steps.findIndex(step => this.activeSection[step.key]);
    },
    prices () {
      const segmentPrices = this.segmentTotals.reduce((result, price) => {
        result[price.code] = price.value;
        return result;
      }, {});
      return segmentPrices
    },
    checkoutFaqsBlock () {
      const block = this.cmsBlocks.find((_) => _.identifier === 'checkout_faqs_block') || {}
      return block.content || ''
    }
  },
  watch: {
    currentStepIndex (newValue) {
      this.setStep(newValue)
    },
    '$route' (newValue) {
      this.updateStepMobile(newValue)
      this.openAccordionNew()
    }
  },
  methods: {
    ...mapActions('checkout', ['setStep']),
    onPrepareOrder (orderId) {
      this.$store.state.order.current = this.prepareOrder()
    },
    onAfterPersonalDetails (personalDetailsData, validationResult, shippingData) { // overrides Checkout's Mixin
      this.personalDetails = personalDetailsData
      this.validationResults.personalDetails = validationResult

      this.activateSection('shipping')
      this.savePersonalDetails()

      if (shippingData) {
        this.shipping = shippingData
        this.validationResults.shipping = validationResult
        this.saveShippingDetails()
      }
      this.focusedField = null
    },
    onBeforeShippingMethods (country) { // overrides Checkout's Mixin
      this.$store.dispatch('checkout/updatePropValue', ['country', country])
      this.$forceUpdate()
    },
    async onAfterShippingMethodChanged (payload, shippingData) { // overrides Checkout's Mixin
      // TODO overwrite this
      await this.$store.dispatch('cart/syncTotals', {
        forceServerSync: true,
        methodsData: payload,
        skipPullingShippingMethods: payload.skipPullingShippingMethods
      })
      this.shippingMethod = payload
      if (shippingData) {
        this.shipping = shippingData
        this.saveShippingDetails()
      }
    },
    changeStep (nextStep) {
      if (nextStep < this.currentStepIndex) {
        this.$bus.$emit('checkout-before-edit', this.steps[nextStep].key);
      }
    },
    showNotification ({ type, message }) {
      this.$store.dispatch('notification/spawnNotification', {
        type,
        message,
        action1: { label: this.$t('OK') }
      });
    },
    notifyEmptyCart () {
      this.showNotification({
        type: 'warning',
        message: this.$t(
          'Shopping cart is empty. Please add some products before entering Checkout'
        )
      });
    },
    notifyOutStock (p) {
      this.showNotification({
        type: 'danger',
        message: p.name + ' ' + this.$t('is out of stock!')
      });
    },
    notifyNotAvailable () {
      this.showNotification({
        type: 'danger',
        message: this.$t('Some of the ordered products are not available!')
      });
    },
    notifyStockCheck () {
      this.showNotification({
        type: 'warning',
        message: this.$t(
          'Stock check in progress, please wait while available stock quantities are checked'
        )
      });
    },
    notifyNoConnection () {
      this.showNotification({
        type: 'warning',
        message: this.$t(
          'There is no Internet connection. You can still place your order. We will notify you if any of ordered products is not available because we cannot check it right now.'
        )
      });
    },
    openAccordion (digit) {
      if (this.activeAccordion !== 0 && this.activeAccordion !== digit) {
        document.getElementsByClassName(`subscription-${this.activeAccordion}`)[0].classList.remove('active')
      }
      this.activeAccordion = this.activeAccordion === digit ? 0 : digit
      document.getElementsByClassName(`subscription-${digit}`)[0].classList.toggle('active')
    },
    checkIsMobileView () {
      this.isMobile = window.innerWidth <= 767
    },
    hideTrustBadge () {
      if (config.trustbadge && config.trustbadge.hideOnCheckout) {
        Array.from(document.querySelectorAll('[id^="trustbadge-container"]')).map((item) => { item.classList.add('trustbadge-hide'); });
      }
    },
    updateStepMobile (newValue) {
      if (newValue.hash === '' || newValue.hash === '#personalDetails') {
        if (this.isMobile) {
          document.getElementsByClassName('checkout_parent')[0].classList.add('first-step')
          document.getElementsByClassName('checkout_parent')[0].classList.remove('second-step')
          document.getElementsByClassName('checkout_parent')[0].classList.remove('third-step')
          document.getElementsByClassName('o-header-checkout')[0].classList.remove('checkout-logo-hide')
        }
        this.hideTrustBadge()
      }
      if (newValue.hash === '#shipping') {
        if (this.isMobile) {
          document.getElementsByClassName('checkout_parent')[0].classList.add('second-step')
          document.getElementsByClassName('checkout_parent')[0].classList.remove('first-step')
          document.getElementsByClassName('checkout_parent')[0].classList.remove('third-step')
          document.getElementsByClassName('o-header-checkout')[0].classList.remove('checkout-logo-hide')
        }
        this.hideTrustBadge()
      }
      if (newValue.hash === '#payment') {
        if (this.isMobile) {
          document.getElementsByClassName('checkout_parent')[0].classList.add('third-step')
          document.getElementsByClassName('o-header-checkout')[0].classList.add('checkout-logo-hide')
          document.getElementsByClassName('checkout_parent')[0].classList.remove('first-step')
          document.getElementsByClassName('checkout_parent')[0].classList.remove('second-step')
        }
        this.hideTrustBadge()
      }
    },
    showCheckoutFaqsStepBlock (stepIndex) {
      const identifier = 'checkout_faqs_block_' + (stepIndex + 1);
      const block = this.cmsBlocks.find((_) => _.identifier === identifier) || {}
      return block.content || ''
    },
    openAccordionNew () {
      const accordionItemAll = document.querySelectorAll('.toggle-question');
      accordionItemAll && accordionItemAll.length > 0 && accordionItemAll.forEach((item, index) => {
        item.addEventListener('click', (e) => {
          if (item.classList[1] === undefined) {
            item.classList.add('active')
          } else {
            item.classList.remove('active')
          }
        })
      })
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

#checkout {
  box-sizing: border-box;
  h3 {
    margin-bottom: 0;
    padding: 0 var(--spacer-xs) var(--spacer-sm) var(--spacer-xs);
    display: flex;
    align-items: center;
    .cart-icon {
      margin-right: 5px;
    }
    @include for-desktop {
      flex-wrap: wrap;
      display: none;
    }
    border-bottom: solid 1px #ddd;
    span {
      margin-left: auto;
      font-size: 0;
      line-height: 25px;
    }
    @include for-mobile {
      .sf-property {
        &__main {
          display: none;
        }
      }
    }
  }
  @include for-desktop {
    padding: 0 var(--spacer-sm);
    max-width: var(--container-width);
    margin: 0 auto;
  }
  @include for-mobile {
    .o-order-summary {
      display: none;
    }
    &.first-step {
      .o-personal-details {
        display: block;
      }
    }
    &.active.first-step {
      .o-order-summary {
        display: block;
      }
    }
    &.second-step {
      .o-order-summary {
        display: none;
      }
    }
    &.active.second-step {
      .checkout__main,
      .o-order-summary {
        display: block;
      }
    }
    &.third-step {
      .checkout__aside {
        display: none;
      }
    }
  }
}
::v-deep .checkout {
  @include for-desktop {
    display: flex;
    margin-bottom: var(--spacer-lg);
  }
  &__main {
    @include for-desktop {
      flex: 1;
      padding: var(--spacer-xs) var(--spacer-sm) var(--spacer-sm);
      background: var(--c-white);
    }
  }
  &__aside {
    @include for-desktop {
      margin: 0 var(--spacer-lg) var(--spacer-sm) 0;
      width: 30%;
      max-width: 22rem;
    }
  }
  &__aside-order {
    box-sizing: border-box;
    width: 100%;
    background: var(--c-light-variant);
    padding: var(--spacer-base);
    box-shadow: none;
    @include for-mobile {
      display: none;
    }
  }

  &__faq-content-wrapper {
    --faq-content-padding-mobile: var(--spacer-sm);

    box-sizing: border-box;
    width: 100%;
    padding: var(--spacer-base);
    box-shadow: none;

    @include for-mobile {
      padding-left: var(--faq-content-padding-mobile, var(--spacer-base));
      padding-right: var(--faq-content-padding-mobile, var(--spacer-base));
    }
  }

  .sf-steps {
    --steps-border-width: 0 0 3px 0;
    --steps-border-color: var(--c-light-variant);
    --steps-step-color: var(--c-dark-variant);
    --steps-step-after-background: var(--c-light-variant);
    --steps-progress-background: var(--c-secondary);
    --steps-content-padding: 0 var(--spacer-sm);
    --steps-step-padding: var(--spacer-xs) var(--spacer-2xs);
    --step-font-size: var(--font-size--sm);
    @include for-desktop {
      --steps-content-padding: 0;
    }
    &__header {
      box-shadow: none;
      @include for-mobile {
        display: none
      }
      .sf-button {
        font-family: var(--font-family--primary);
      }
    }
    &__step::after {
      content: "";
      background: var(--steps-step-after-background, transparent);
      position: absolute;
      border-radius: 100%;
      left: 49%;
      bottom: -3.1px;
      left: 49%;
      width: 5px;
      height: 5px;
      @include for-desktop {
        bottom: -4.6px;
        width: 6px;
        height: 6px;
      }
    }
    &__step {
      --steps-step-cursor: default;
      --steps-step-color: var(--c-gray-variant);
      &.current,
      &.is-done {
        --steps-step-color: var(--c-secondary);
        --steps-step-after-background: var(--c-secondary);
        &:hover {
          --steps-step-color: var(--c-black);
          --steps-step-after-background: var(--c-black);
        }
      }
      &.is-done {
        --steps-step-cursor: pointer;
        --step-font-weight: var(--font-weight--medium);
      }
    }
  }
  .title {
    --heading-padding: var(--spacer-sm) 0 0;
    --heading-title-font-size: var(--h3-font-size);
    --heading-title-font-weight: var(--font-weight--semibold);
    @include for-desktop {
      --heading-padding: var(--spacer-sm) 0 var(--spacer-xs);
    }
  }
  .form {
    margin: var(--spacer-sm) 0 0 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: var(--spacer-lg) 0 0 0;
    .m-price-summary {
      width: 100%;
    }
    &__group {
      display: flex;
      align-items: center;
    }
    &__checkbox {
      margin: var(--spacer-base) 0;
    }
    &__radio-group {
      margin: 0;
      flex: 0 0 100%;
    }
    &__action {
      margin: var(--spacer-sm) 0 0 0;
      @include for-desktop {
        width: 100%;
        margin: var(--spacer-3xs) 0;
      }
    }
    @include for-mobile {
      &__checkbox {
        --checkbox-font-family: var(--font-family--primary);
        --checkbox-font-weight: var(--font-weight--light);
        --checkbox-font-size: var(--font-size--sm);
      }
      .country-dropdown, .form__summary, .form__action {
        width: 100%;
      }
    }
    &__element {
      margin: 0 0 var(--spacer-sm) 0;
      flex: 0 0 100%;
      &--half {
        flex: 1 1 50%;
        &-even {
          padding: 0 0 0 var(--spacer-xl);
          @include for-mobile {
            padding: 0 0 0 var(--spacer-xs);
          }
        }
      }
      @include for-mobile {
        label {
          font-size: var(--font-size--sm);
        }
      }
    }
    @include for-desktop {
      &__action {
        flex: 0 0 100%;
        display: flex;
      }
      &__button {
        --button-width: auto;
      }
      &__radio-group {
        margin: 0 0 var(--spacer-xs);
      }
    }
  }
}

</style>

<style lang="scss">
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

#checkout {
  .checkout{
    display: block;
    @include for-mobile {
      display: flex;
      flex-direction: column;
    }
    .checkout__main {
      width: 67%;
      float: right;
      @include for-mobile {
        float: none;
        width: 100%;
      }
    }
    .checkout__aside{
      width:28%;
      max-width: none;
      @include for-desktop {
        .m-promo-code {
          display: none;
        }
      }
      @include for-mobile {
        float: none;
        order: -1;
        width: 100%;
        .promo-code__toggle-coupon {
          padding: var(--spacer-15) 0;
        }
        .promo-code__coupon {
          padding: 0 var(--spacer-15);
        }
      }
    }
    .checkout__aside_bottom{
      width:28%;
      float: left;
      @include for-mobile {
        float: none;
        width: 100%;
      }
    }
  }
  @include for-mobile {
    .checkout__aside {
      h3 {
        span.sf-property {
          &__name {
            display: flex;
            align-items: center;
            color: var(--c-true-black);

            &:after {
              content: "";
              border: solid var(--c-true-black);
              border-width: 0 2px 2px 0;
              display: inline-block;
              padding: 4px;
              vertical-align: middle;
              transform: rotate(45deg);
              margin-left: 5px;
              margin-top: -3px;
            }
          }
        }
      }
    }
    .o-personal-details {
      display: none;
    }
    &.active {
      .o-personal-details {
        display: block;
      }
      .checkout__aside {
        h3 {
          span.sf-property__name {
            &:after {
              transform: rotate(-134deg);
              margin-top: 4px;
            }
          }
        }
      }
    }
    .o-order-summary {
      padding: var(--spacer-base) var(--spacer-sm);
      background: var(--c-light-variant);
      border-bottom: solid 1px var(--c-light-variant);
      .collected-product {
        padding: var(--spacer-xs) 0px;
        border-bottom: solid 1px var(--c-light-variant);
        &:last-child {
          border: 0;
        }
      }
      .sf-collected-product {
        &__title {
          margin-right: 102px;
        }
      }
      .collected-product {
        &__properties {
          &:empty {
            margin: 0;
          }
        }
      }
      .sf-collected-product {
        &__configuration {
          padding: 0;
        }
      }
      .sf-collected-product {
        &__price {
          position: absolute;
          right: 0;
          top: 6px;
          .sf-price {
            justify-content: end;
            align-items: end;
          }
          .sf-price {
            &__special {
              margin-right: 0;
              color: var(--c-true-black);
              font-weight: 600;
            }
          }
        }
      }
      .sf-collected-product {
        &__quantity-selector {
          background: none;
          margin: var(--spacer-sm) 0 0 0;
          border: 0;
          height: auto;
          .has-text {
            width: auto;
          }
          input {
            border: solid 1px var(--c-light);
            width: 55px;
            margin: 0 var(--spacer-xs);
            padding: var(--spacer-xs) var(--spacer-2xs);
            border-radius: var(--spacer-2xs);
          }
          .sf-button {
            background: var(--c-light);
            border-radius: var(--spacer-base);
            font-size: 26px;
            padding: var(--spacer-xs) 12px;
            height: 35px;
          }
        }
      }
      .sf-heading {
        display: none;
      }
      .promo-code {
        padding: var(--spacer-xs);
        border: solid 1px var(--c-light);
        margin-bottom: var(--spacer-xs);
        border-radius: var(--spacer-2xs);
        .sf-button {
          border-radius: 0px var(--spacer-2xs) var(--spacer-2xs) 0px;
          height: 34px;
        }
        input {
          border: solid 1px var(--c-light) !important;
          border-radius: var(--spacer-2xs) 0px 0px var(--spacer-2xs);
        }
        .promo-code {
          &__coupon {
            margin-top: var(--spacer-xs)
          }
        }
        .sf-input {
          &__bar {
            display: none;
          }
        }
      }
      .sf-circle-icon {
        &__icon {
          background: var(--c-true-black);
          border-radius: 100%;
          padding: 3px;
          svg {
            height: 12px;
            width: 12px;
            path {
              fill: var(--c-white);
            }
          }
        }
      }
    }
    &.third-step {
      .o-payment .sf-heading {
        display: none;
      }
    }
  }
  .country-dropdown{
    .sf-select{
      position: relative;
      &::after{
          content: '';
          position: absolute;
          right: 6px;
          top: 21px;
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          border: solid black;
          border-width: 0 2px 2px 0;
          display: inline-block;
          padding: 3px;
          vertical-align: middle;
          pointer-events: none;
        }
      .sf-select__dropdown{
        appearance: none;
        font-size: 16px;
        padding-bottom: 13px;
        height: auto;
        border-radius: 0;
        background: #fff;
        padding-right: 25px;
      }
    }
  }
}
.o-header-checkout.checkout-logo-hide {
  @include for-mobile {
    display: none;
  }
}
.trustbadge-hide {
  display: none;
}

</style>
