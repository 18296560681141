<template>
  <div class="o-payment">
    <SfHeading
      :title="`${$t('Payment')}`"
      :level="2"
      class="sf-heading--left sf-heading--no-underline title"
    />
    <OPaymentIframe v-if="useCollector" />
    <!-- This dummy container below is needed because src\modules\payment-cash-on-delivery\index.ts
         tries to inject here a component with payment description -->
    <div v-show="false" id="checkout-order-review-additional-container" />
  </div>
</template>
<script>
import config from 'config'
import { Payment } from '@vue-storefront/core/modules/checkout/components/Payment';
import SfHeading from '@storefront-ui/vue/src/components/atoms/SfHeading/SfHeading.vue';
import { createSmoothscroll } from '~/theme/helpers';
import OPaymentIframe from 'src/modules/collector/components/o-payment-iframe.vue'

export default {
  name: 'OPayment',
  components: {
    SfHeading,
    OPaymentIframe
  },
  mixins: [Payment],
  computed: {
    useCollector () {
      return config.checkout.useCollector
    }
  },
  mounted () {
    createSmoothscroll(document.documentElement.scrollTop || document.body.scrollTop, 0);
  }
};
</script>
